/* #### Generated By: http://www.cufonfonts.com #### */

/* Poppins */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('./Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('./Poppins/Poppins-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('./Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('./Poppins/Poppins-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('./Poppins/Poppins-SemiBold.ttf') format('truetype');
}

/* Ginora */
@font-face {
    font-family: 'Ginora Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Ginora Sans Regular'), url('./Ginora Regular/GinoraSans-Regular.woff2') format('woff');
}


@font-face {
    font-family: 'Ginora Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Ginora Sans Oblique'), url('./Ginora/Ginóra Sans Oblique.woff') format('woff');
}


@font-face {
    font-family: 'Ginora Sans';
    font-style: normal;
    font-weight:300;
    src: local('Ginora Sans Light'), url('./Ginora/Ginóra Sans Light.woff') format('woff');
}


@font-face {
    font-family: 'Ginora Sans';
    font-style: italic;
    font-weight:300;
    src: local('Ginora Sans Light Oblique'), url('./Ginora/Ginóra Sans Light Oblique.woff') format('woff');
}


@font-face {
    font-family: 'Ginora Sans';
    font-style: normal;
    font-weight:500;
    src: local('Ginora Sans Semi Bold'), url('./Ginora Regular/GinoraSans-SemiBold.woff2') format('woff');
}


@font-face {
    font-family: 'Ginora Sans';
    font-style: italic;
    font-weight:500;
    src: local('Ginora Sans Semi Bold Oblique'), url('./Ginora/Ginóra Sans Semi Bold Oblique.woff') format('woff');
}


@font-face {
    font-family: 'Ginora Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Ginora Sans Bold'), url('./Ginora Regular/GinoraSans-SemiBold.woff2') format('woff');
}


@font-face {
    font-family: 'Ginora Sans';
    font-style: italic;
    font-weight: 700;
    src: local('Ginora Sans Bold Oblique'), url('./Ginora/Ginóra Sans Bold Oblique.woff') format('woff');
}

/* Trajan */

@font-face {
    font-family: 'Trajan';
    font-style: normal;
    font-weight: normal;
    src: url('./Trajan/Trajan Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Trajan Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./Trajan/TrajanPro-Regular.woff2') format('woff2');
}

/* Syne */

@font-face {
    font-family: 'Syne Bold';
    src: local('Syne Bold'), url('./Syne/Syne-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Syne Bold';
    src: local('Syne Bold'), url('./Syne/Syne-Bold.woff2') format('woff');
}

@font-face {
    font-family: 'Syne Extra';
    src: local('Syne Extra'), url('./Syne/Syne-Extra.woff') format('woff');
}

@font-face {
    font-family: 'Syne Extra';
    src: local('Syne Extra'), url('./Syne/Syne-Extra.woff2') format('woff');
}

@font-face {
    font-family: 'Syne Mono';
    src: local('Syne Mono'), url('./Syne/Syne-Mono.woff') format('woff');
}

@font-face {
    font-family: 'Syne Mono';
    src: local('Syne Mono'), url('./Syne/Syne-Mono.woff2') format('woff');
}


@font-face {
    font-family: 'Syne Regular';
    src: local('Syne Regular'), url('./Syne/Syne-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Syne Regular';
    src: local('Syne Regular'), url('./Syne/Syne-Regular.woff2') format('woff');
}

/* Montserrat */

@font-face {
    font-family: 'Montserrat Regular';
    src: url('./Montserrat/Montserrat-Regular.ttf') format('truetype');
}